//import firebase from "firebase";
//import { functions } from "firebase";
//import config from "../../../keys/firebaseconfig"

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions';

let config = {
	apiKey: "AIzaSyDIqRLy0kTlrz0-WP3Gks3c1wP1Oqtj-I4",
	authDomain: "jcgraphix-f39de.firebaseapp.com",
	databaseURL: "https://jcgraphix-f39de.firebaseio.com",
	projectId: "jcgraphix-f39de",
	storageBucket: "jcgraphix-f39de.appspot.com",
	messagingSenderId: "679812597505",
	appId: "1:679812597505:web:89ed9596ee5df342"
}

class Firebase {
   constructor () {
      if (typeof window !== 'undefined') {
       firebase.initializeApp(config)
       this.auth = firebase.auth();
       this.db = firebase.firestore();
       this.functions = firebase.functions();
      }
   }
 }

export default new Firebase()

/*

// Start Firebase
let Firebase = firebase.initializeApp(config);

// Create the auth object
const auth = firebase.auth();

// Create the firestone object
const db = Firebase.firestore();
db.settings({
	// timestampsInSnapshots: true Seems like this is no longer needed
})

//const firebaseFunctions = functions();

export { 
	auth,
   db
};


*/





/*

let obj = {
	name: "This is the name",
	content: "This is the content"
	timestamp: Date.now(), 
   timestamphuman: new Date().toLocaleString()
}

db.collection('name').add(obj);  

*/