/*
	This component levels things out accros broweser types etc to give a basic starting point

	Use this in the layout compoments

	import { Normalize } from '../shipityo'

	const Layout = ({ children }) => (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
				}
			`}
			render={data => (
				<Normalize>
				<Header siteTitle={data.site.siteMetadata.title} />
				<div>
					<main>{children}</main>
					<footer>
						© {new Date().getFullYear()}
					</footer>
				</div>
				</Normalize>
			)}
		/>
	)

	or consider wrapping the root component in the gatsby-browser file.

*/

import React, { Fragment } from "react"
import "./normalize.css"

function Normalize({children}) {
	return (
		<Fragment>
			{children}
		</Fragment>
	)
}

export default Normalize
