/*
This compoment uses React Helmet to add the basic seo info to the rendered pages on the generated website.
It checks for the title, description and baseurl and author info in the gatsby config file.
The user can also optionally pass in information from the active page that is unique to that page such as the 
title, description and keywords. 
The gatsby default seemed to also have lang and meta, but perhaps these can be added later.

gatsby-config.js:

module.exports = {
  siteMetadata: {
    title: `ShipitYo Starter`,
    description: `This is the description`,
    baseurl: 'localhost:8000',
    author: "ShipitYo"
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
  ]
}

Example usage in the index page file

import { SEO } from 'shipityo'

<SEO 
   title="ShipitYo Starter Home Page" 
   keywords={[`shipityo`]} 
   description="ShipitYo helps us ship our projects faster" 
   author="ShipitYo"
/> 

*/

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description, keywords, author  }) {
  const { siteQueryDataSEO } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  description
                  baseurl
                  author
               }
            }
         }
      `
  )

   const helmetTitle = title || siteQueryDataSEO.siteMetadata.description;
   const helmetDescription = description || siteQueryDataSEO.siteMetadata.description;
   //const helmetAuthor = author || siteQueryDataSEO.siteMetadata.author;
   const helmetAuthor = author || "ShipitYo"
   const meta = [];

  return (
    <Helmet
      htmlAttributes={{
        lang:"en",
      }}
      title={title}
      titleTemplate={`%s | ${helmetTitle}`}
      meta={[
        {
          name: `description`,
          content: helmetDescription,
        },
        {
          property: `og:title`,
          content: helmetTitle,
        },
        {
          property: `og:description`,
          content: helmetDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: helmetAuthor,
        },
        {
          name: `twitter:title`,
          content: helmetTitle,
        },
        {
          name: `twitter:description`,
          content: helmetDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
   title: 'ShipitYo Default Project',
   description: `ShipitYo helps us ship our projects faster`,
   keywords: [],
   lang: `en`,
   meta: [],
}

SEO.propTypes = {
   title: PropTypes.string.isRequired,
   description: PropTypes.string,
   keywords: PropTypes.arrayOf(PropTypes.string),
}

export default SEO
